/* Open AI */
#bg {fill:#5135FF}
.rect0 {fill:url(#rg0)}.rect1 {fill:url(#rg1)}.rect2 {fill:url(#rg2)}.rect3 {fill:url(#rg3)}

.mono {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Courier, monospace;
}

.color-white {
  color: #fff !important;
}

html, body {
  width: 100%;
}

body {
  font-family: Charter,Georgia,serif;
  font-family: 'New York', ui-serif, Georgia, serif;
  line-height: 1.4;
  letter-spacing: 0;
  background-color: #fff;
  background-color: rgba(var(--bg), 1);
  color: #000;
  color: rgba(var(--fg), 1);
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  word-wrap: break-word;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: system-ui, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}

.trbl-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.position-absolute {
  position: absolute !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.container {
  max-width: 1470px;
  width: 93.3333333333%;
  margin-left: auto;
  margin-right: auto;
}

.header-home-container {
  padding-top: 25vw;
  padding-bottom: 2rem;
  text-align: center;
}

@media (min-width: 720px) {
  .container {
    width: 93.3333333333%;
  }
}

.container {
  max-width: 800px;
  width: 93.3333333333%;
  margin-left: auto;
  margin-right: auto;
}

.header-home {
  --fg: 255,255,255;
  --bg: 0,0,0;
}

.btns .btn-padded:not(:last-child) {
  margin-right: .8333333333em;
}

.btn-padded.btn-circle {
  padding: 0.7em 1.333333em 0.6em;
  border-radius: 3em;
  margin-left: -1px;
}
.engine-webkit .btn-padded {
  -webkit-backdrop-filter: blur(1.5rem);
  backdrop-filter: blur(1.5rem);
}

.btn-padded.btn-bg-bg {
  background-color: rgba(255,255,255,0.1);
  background-color: rgba(var(--bg), 0.1);
}
.btn-padded {
  color: #000 !important;
  color: rgba(var(--fg), 1) !important;
  background-color: rgba(0,0,0,0.05);
  background-color: rgba(var(--fg), 0.05);
  padding: 0.7em 1.125em 0.6em;
  margin-bottom: .8333333333em;
}
.btn, button {
  cursor: pointer;
  background-color: inherit;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  font-family: ColfaxAI,Helvetica,sans-serif;
  font-size: .6666666667rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none !important;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
a {
  background-color: transparent;
}

:root {
  --fg: 0, 0, 0;
  --bg: 255, 255, 255;
  --black: #000;
  --white: #fff;
  --ghost: #F7F7F7;
  --ivory: #F6F6F4;
  --light-wash: #F7FBFB;
  --medium-wash: #EFF7F8;
  --dark-wash: #E6F3F3;
  --light-warm-gray: #EEEDEE;
  --dark-warm-gray: #A3ACB0;
  --cool-gray-0\.5: #EDEEF1;
  --cool-gray-1: #C5C5D2;
  --cool-gray-1\.5: #AEAEBB;
  --cool-gray-2: #8E8EA0;
  --cool-gray-3: #6E6E80;
  --cool-gray-4: #404452;
  --pink: #F69CFF;
  --magenta: #DD5CE5;
  --red: #BD1C5F;
  --bright-red: #EF4146;
  --orange: #FF5828;
  --golden: #FFA50F;
  --yellow: #EBE93D;
  --light-green: #50DE66;
  --dark-green: #00A17A;
  --light-blue: #C4E6F4;
  --medium-blue: #00B7FF;
  --dark-blue: #5135FF;
  --navy-blue: #3B2479;
  --light-purple: #6B40D8;
  --dark-purple: #412991;
  --light-grayish-purple: #CDC3CF;
  --medium-grayish-purple: #9C88A3;
  --dark-grayish-purple: #562F5F;
  --purple-black: #2D0F41;
  --light-black: #191927;
  --medium-black: #0E0E1A;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 720px;
  --breakpoint-lg: 960px;
  --breakpoint-xl: 1280px;
  --sans-serif: ColfaxAI, Helvetica, sans-serif;
  --serif: Charter, Georgia, serif;
  --monospace: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  --icons: IconsAI;
  --v: 1.4rem;
  --indent: 1.25em;
}


/* Me */

.container.white {
  background-color: #fff;
}
.panel {
  background-color: #EEEDEE;
  padding: 1rem 1.25rem 2rem 1.25rem;
  margin-bottom: 1rem;
}

.panel h3 {
  margin-bottom: 1rem;
}

@media (min-width: 720px) {
  .grid-3 {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-gap: 1.33rem;
  }
}

/* https://flaviocopes.com/rotate-image/ */
.rotate {
  animation: rotation 60s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}